'use client';
import { useSearchParams } from 'next/navigation';
import { ErrorPage } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { withSearchParams } from '@core-systems/utils';
import { i18nNamespace } from '../../../shared/i18n-namespaces';

export function DynamicErrorPage({ pathToLogin }: { pathToLogin: string }): React.JSX.Element {
  const searchParams = useSearchParams();
  const uriToLogin = withSearchParams(pathToLogin, searchParams);
  return <StaticErrorPage uriToLogin={uriToLogin} />;
}

export function StaticErrorPage({ uriToLogin }: { uriToLogin: string }): React.JSX.Element {
  const { t } = useClientTranslations(i18nNamespace.COMMON);

  return (
    <ErrorPage
      title={t('errors.not-found.title')}
      code="404"
      message={t('errors.not-found.description')}
      action={{ title: t('errors.not-found.cta'), url: uriToLogin }}
    />
  );
}
