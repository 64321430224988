'use client';
import React, { Suspense } from 'react';
import { useSearchParams } from 'next/navigation';
import { Link } from '@design-system';
import { AuthAction, LoginMethod, pathByAction } from '@core-systems/auth';
import { withSearchParams } from '@core-systems/utils';

export type BackToLoginLinkProps = {
  locale: string;
  method: LoginMethod;
  children: React.ReactElement;
};

export function BackToLoginLink({ children, locale, method }: BackToLoginLinkProps): React.JSX.Element {
  return (
    <Suspense
      fallback={
        <BackToLoginLinkStatic locale={locale} method={method}>
          {children}
        </BackToLoginLinkStatic>
      }
    >
      <BackToLoginLinkDynamic locale={locale} method={method}>
        {children}
      </BackToLoginLinkDynamic>
    </Suspense>
  );
}

function BackToLoginLinkDynamic({ children, locale, method }: BackToLoginLinkProps): React.JSX.Element {
  const currentSearchParams = useSearchParams();
  const path = pathByAction(locale, method, AuthAction.Login);
  const backToLoginUrl = withSearchParams(path, currentSearchParams);

  return <Link href={backToLoginUrl}>{children}</Link>;
}

function BackToLoginLinkStatic({ children, locale, method }: BackToLoginLinkProps): React.JSX.Element {
  const path = pathByAction(locale, method, AuthAction.Login);
  return <Link href={`/${locale}/${method}login`}>{children}</Link>;
}
