'use client';

import { PropsWithChildren, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { TermsDocumentType } from '../types/terms-document';
import { useTerms } from './terms.hook';

export const RequireConsentForDocuments = ({
  children,
  documents,
  campus,
}: PropsWithChildren<{
  documents: TermsDocumentType[];
  campus?: string;
}>): PropsWithChildren['children'] => {
  const terms = useTerms();
  const session = useSession();
  const userId = session?.data?.user.id;

  useEffect(() => {
    if (!terms.isReady || !userId) return;
    terms.openDocumentsForConsent(documents, userId, campus);
  }, [campus, documents, userId, terms]);

  return children;
};
